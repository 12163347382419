/***Navbar***/
.link {
  display: inline-block;
  position: relative;
}

.link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom left;
  transition: transform 0.2s ease-out;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
